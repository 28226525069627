<template>
  <div id="order-view-states">
    <b-overlay :show="quoteLoading">
      <b-card>
        <b-row v-if="quoteSelected.quote.state">
          <b-col
            v-if="!quoteSelected.offerOrCommissionQuote"
            :cols="quoteSelected.paymentIntent ? 6 : 12"
            xl="12"
            :class="['mb-0', { 'mb-xl-1': quoteSelected.paymentIntent }]"
          >
            <h6 class="font-weight-bold text-center">{{ `${quoteTitle} ${$t('state.title')} : ` }}</h6>
            <div class="text-center">
              <b-badge v-if="quoteSelected.quote.cancelled" class="text-wrap" variant="light-danger" pill>
                {{ $t('state.cancelled') }}
              </b-badge>
              <b-badge v-if="quoteSelected.quote.refused" class="text-wrap" variant="light-danger" pill>
                {{ $t('state.refused') }}
              </b-badge>
              <b-badge v-if="quoteSelected.quote.cancelledDraft" class="text-wrap" variant="light-danger" pill>
                {{ $t('state.cancelled_draft') }}
              </b-badge>
              <b-badge v-if="!quoteSelected.quote.cancelled && !quoteSelected.quote.refused && !quoteSelected.quote.cancelledDraft" class="text-wrap" :variant="`${resolveQuoteStateVariant(quoteSelected.quote.state)}`" pill>
                {{ quoteSelected.quote.state | enumTranslate('quote_state') }}
              </b-badge>
            </div>
          </b-col>
          <b-col v-if="quoteSelected.paymentIntent" cols="6" xl="12">
            <h6 class="font-weight-bold text-center">{{ ` ${$t('order.payment_state')} : ` }}</h6>

            <div v-if="quoteSelected.paymentIntent.status || quoteSelected.paymentIntent.refund" class="text-center">
              <b-badge v-if="quoteSelected.paymentIntent.status === 'canceled'" class="text-wrap" :variant="`${resolvePaymentIntentStatusVariant(quoteSelected.paymentIntent.status)}`" pill>
                {{ quoteSelected.paymentIntent.status | enumTranslate('payment_intent_status') }}
              </b-badge>
              <b-badge v-else-if="quoteSelected.paymentIntent.refund && quoteSelected.paymentIntent.refund.id && quoteSelected.paymentIntent.refund.totalRefund" class="text-wrap" variant="warning" pill>
                {{ $t('enum.quote_state.refunded') }}
              </b-badge>
              <b-badge v-else-if="quoteSelected.paymentIntent.refund && quoteSelected.paymentIntent.refund.id && !quoteSelected.paymentIntent.refund.totalRefund" class="text-wrap" variant="warning" pill>
                {{ $t('enum.quote_state.partial_refunded') }}
              </b-badge>
              <b-badge v-else class="text-wrap" :variant="`${resolvePaymentIntentStatusVariant(quoteSelected.paymentIntent.status)}`" pill>
                {{ quoteSelected.paymentIntent.status | enumTranslate('payment_intent_status') }}
              </b-badge>
            </div>
          </b-col>
        </b-row>
        <!-- up state -->
        <b-row v-if="quoteSelected.quote.cancelled" class="mt-1">
          <b-col v-if="quoteSelected.quote.cancellationPoliciesApplied !== null" cols="12" class="text-center">
            <h6 class="font-weight-bold">{{ ` ${$tc('cancellation_policy.title', 1)} : ` }}</h6>
            <b-badge class="text-wrap" :variant="quoteSelected.quote.cancellationPoliciesApplied ? 'light-success' : 'light-danger'" pill>
              <span v-if="quoteSelected.quote.cancellationPolicyType">
                {{ quoteSelected.quote.cancellationPolicyType | enumTranslate('quote_cancellation_policy_type') }}
              </span>
              {{ quoteSelected.quote.cancellationPoliciesApplied ? $t('common.applied') : $t('common.not_applied') }}
            </b-badge>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import UIHelper from '@/helpers/ui'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'OrderViewStates',

  mixins: [UIHelper],

  computed: {
    ...mapState('quote', ['quoteSelected', 'quoteLoading']),
    ...mapGetters('quote', ['quoteTitle']),
  },
}
</script>

<style lang="scss" scoped>
.invoice-states {
  padding: 0.786rem 1.5rem;
  height: 38px;
}
.p-overflow {
  width: inherit;
  text-overflow: ellipsis;
}
</style>
